<template>
  <div
    v-if="genus"
    class="flex flex-col w-full"
  >
    <van-swipe
      :autoplay="3000"
      lazy-render
    >
      <van-swipe-item
        v-for="image in images"
        :key="image"
      >
        <img :src="image">
      </van-swipe-item>
    </van-swipe>
    <div class="w-full p-3">
      <h3 class="text-2xl font-bold leading-7">
        {{ genus.name }}
      </h3>
      <div class="flex flex-col mt-2 text-sm">
        <div class="flex items-center mb-1 text-sm text-gray-500">
          英文名称：{{ genus.englishName }}
        </div>
        <div class="flex items-center mb-1 text-sm text-gray-500">
          更新时间：{{ $dayjs(genus._updatedAt).format('YYYY-MM-DD HH:mm:ss') }}
        </div>
        <div
          v-if="genus.floweringMonthStart"
          class="flex items-center mb-1 text-sm text-gray-500"
        >
          花期：{{ genus.floweringMonthStart }}月<template
            v-if="
              genus.floweringMonthEnd &&
                genus.floweringMonthEnd != genus.floweringMonthStart
            "
          >
            - {{ genus.floweringMonthEnd }}月
          </template>
        </div>
      </div>
      <div
        :inner-html.prop="genus.description | markdown"
        class="w-full mt-2 heti text-indent-2em"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentGenusBasic',
  props: {
    genus: {
      type: Object,
      default: null
    }
  },
  computed: {
    images() {
      return (this.genus.images ?? []).concat([this.genus.thumbnail])
    }
  }
}
</script>