<template>
  <section class="min-h-screen">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <Basic :genus="genus" />
      <van-empty
        v-if="!genus"
        description="无法加载树木种类信息，请稍后再试！"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Basic from '@/components/genus/basic'
import share from '@/mixins/share'
export default {
  name: 'PublicTreeGenusViewer',
  components: {
    Basic
  },
  mixins: [share],
  data() {
    return {
      loading: true,
      processing: false,
      genus: null
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    if (this.genus) {
      let options = {
        title: this.genus.name,
        desc: this.genus.summary
      }
      options.imgUrl = this.genus.thumbnail
      this.updateShareData(options)
    }

    if(typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'trees',
          data: {
            $url: 'genus',
            id: this.$route.params.genus
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关树木种类', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let genus = response.result.data
          this.$set(this, 'genus', genus)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取树木种类', err)
        this.genus = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
</style>
